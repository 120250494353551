import * as React from "react"
import Layout from "../components/Layout"

// markup
const NotFoundPage = () => {
  return (
    <Layout>
      <p>
        Ups... Nie mamy takiej strony. 
      </p>
    </Layout>
  )
}

export default NotFoundPage
